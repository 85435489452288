import axios from 'axios';
import CONFIG from 'config';

const { localStorage } = window;

export const getToken = () => {
  return localStorage.getItem('token');
};

export const setToken = (token) => {
  localStorage.setItem('token', token);
};

export const verifyToken = async () => {
  try {
    const { data } = await axios.get(
      `${CONFIG.API_URL}/token-login`,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`,
        }
      }
    );

    return data;
  } catch (e) {
    return null;
  }
};

export const removeToken = () => {
  localStorage.removeItem('token');
};
