import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { login } from 'Redux/Actions/User';
import { useForm, FormProvider } from 'react-hook-form';
import Yup from 'helpers/yup';
import { yupResolver } from '@hookform/resolvers';
import { toast } from 'react-toastify';
import Button from 'Components/Common/Button';
import Link from 'Components/Common/Link';
import TextField from 'Components/Common/Form/TextField';
import Row from 'Components/Common/Row';
import Col from 'Components/Common/Col';
import Section from 'Components/Common/Section';

const validationSchema = Yup.object().shape({
  cpf: Yup.string()
    .label('CPF')
    .required("Favor informar um CPF.")
    .length(14, "O CPF de conter 11 dígitos."),
  password: Yup.string()
    .label('Senha')
    .required("Favor informar uma senha.")
    .min(6, "A senha deve conter no mínimo 6 dígitos.")
    .max(18, "A senha deve conter no mínimo 18 dígitos."),
});

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { pageAfterLogin } = useSelector((state) => state.page);
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit, formState } = methods;
  const { isSubmitting } = formState;

  const onSubmit = async ({ cpf, password }) => {
    try {
      await dispatch(login(cpf, password));
      history.push(pageAfterLogin);
    } catch (e) {
      toast.warn('Não foi possível acessar!');
    }
  };

  return (
    <Row>
      <Col size={12}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Section>
              <TextField label="CPF" mask="999.999.999-99" name="cpf" />
              <TextField label="Senha" name="password" type="password" />
              <Button submit block disabled={isSubmitting}>Acessar</Button>
            </Section>
          </form>
        </FormProvider>
      </Col>
      <Col size={12}>
        <Section>
          <Link to="cadastrar" transparent block>Cadastrar</Link>
        </Section>
      </Col>
      <Col size={12}>
        <Section>
          <Link to="recuperar-senha" transparent block>Recuperar Senha</Link>
        </Section>
      </Col>
    </Row>
  );
}
