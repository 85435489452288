import React from 'react';
import PropTypes from 'prop-types';

const CenterContainer = (props) => {
  const { children } = props;
  return (
    <div style={{
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '5%',
    }}>
      <div style={{
        width: '100vw',
        minWidth: '240px',
        maxWidth: '500px',
      }}>
        { children }
      </div>
    </div>
  )
}

CenterContainer.defaultProps = {
};

CenterContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ])
};

export default CenterContainer;
