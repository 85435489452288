import React, { useEffect } from 'react';
import { getToken } from 'helpers/token';
import { Route, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Page from 'Components/Common/Page';
import CenterContainer from 'Components/Common/CenterContainer';
import Title from 'Components/Common/Title';
import Row from 'Components/Common/Row';
import Col from 'Components/Common/Col';
import Section from 'Components/Common/Section';
import Divider from 'Components/Common/Divider';
import CONFIG from 'config'

export default (props) => {
  const { title, component: Component } = props;
  const history = useHistory();
  const { pageAfterLogin } = useSelector((state) => state.page);
  const token = getToken();

  const verifyToken = () => {
    if (token) {
      history.push(pageAfterLogin);
    }
  };

  useEffect(verifyToken, []);

  return (
    <Route
      {...props}
      component={() => (
        <CenterContainer>
          <Row>
            <Col size={12}>
              <Section>
              <div className="row">
                <div className="col s4 m2"><img alt="Logo" style={{marginTop:'20px',width:'100%'}} src={CONFIG.LOGO}/></div>
                <div className="col s8 m10">
                  <h5><b>{CONFIG.APP_NAME}</b></h5>
                  <span style={{color:'grey'}}>{CONFIG.COMPANY} de {CONFIG.LOCATION}</span>
                </div>
              </div>
              <Divider />
              </Section>
                <Title>{title}</Title>
              <Divider />
              <Section>
                <Page title={title}>
                  <Component />
                </Page>
              </Section>
            </Col>
          </Row>
        </CenterContainer>
      )}
    />
  );
};
