import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Row from 'Components/Common/Row';
import Col from 'Components/Common/Col';
import Section from 'Components/Common/Section';
import Divider from 'Components/Common/Divider';
import Link from 'Components/Common/Link';
import Icon from 'Components/Common/Icon';

const Sidenav = () => {
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const sidenavs = document.querySelectorAll('.sidenav');
    window.M.Sidenav.init(sidenavs);
  }, []);

  return (
    <ul className="sidenav" id="sidenav">
      <Row>
        <Col size={12}>
          <Section>
            <h5>{user.email}</h5>
            {user.cpf}
          </Section>
          <Divider />
        </Col>
      </Row>
      <li>
        <Link to="contracheques" transparent sidenav>
          Contracheques
          <Icon left>description</Icon>
        </Link>
      </li>
      <li>
        <Link to="configuracoes" transparent sidenav>
          Configurações
          <Icon left>settings</Icon>
        </Link>
      </li>
      <li>
        <Link to="sair" transparent sidenav>
          Sair
          <Icon left>exit_to_app</Icon>
        </Link>
      </li>
    </ul>
  )
};

Sidenav.defaultProps = {
};

Sidenav.propTypes = {
};

export default Sidenav;
