import React from 'react';
import PropTypes from 'prop-types';

const Row = (props) => {
  const { children } = props;

  return (
    <div className="row">{ children }</div>
  );
};

Row.defaultProps = {
};

Row.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default Row;
