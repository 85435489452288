import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

// Types
import OnlyPublic from 'Components/Routes/Types/OnlyPublic';
// import Public from 'Components/Routes/Types/Public';
import Private from 'Components/Routes/Types/Private';

// Pages
import Signin from 'Components/Pages/Signin';
import Signup from 'Components/Pages/Signup';
import RecoveryPassword from 'Components/Pages/RecoveryPassword';
import Signout from 'Components/Pages/Signout';
import Paycheck from 'Components/Pages/Paycheck';
import Settings from 'Components/Pages/Settings';

export default () => (
  <Switch>
    <Redirect path="/" to="/entrar" exact={true} />

    <OnlyPublic
      path="/entrar"
      title="Entrar"
      exact={true}
      component={Signin}
    />
    <OnlyPublic
      path="/cadastrar"
      title="Cadastrar"
      exact={true}
      component={Signup}
    />
    <OnlyPublic
      path="/recuperar-senha"
      title="Recuperar Senha"
      exact={true}
      component={RecoveryPassword}
    />
    <Private
      path="/sair"
      title="Sair"
      exact={true}
      component={Signout}
    />
    <Private
      path="/contracheques"
      title="Contracheques"
      exact={true}
      component={Paycheck}
    />
    <Private
      path="/configuracoes"
      title="Configurações"
      exact={true}
      component={Settings}
    />

    <Redirect path="*" to="/" />
  </Switch>
);
