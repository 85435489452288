import { combineReducers } from 'redux';

import userReducer from './User';
import pageReducer from './Page';

const rootReducer = combineReducers({
  user: userReducer,
  page: pageReducer,
});

export default rootReducer;
