import axios from 'axios';
import { useDispatch } from 'react-redux';
import CONFIG from 'config';
import { logout } from 'Redux/Actions/User';
import { getToken } from 'helpers/token';

let dispatch = null;

const request = async (params) => {
  try {
    const url = (params.url.indexOf('http://') > -1 || params.url.indexOf('https://') > -1)
      ? params.url : CONFIG.API_URL + params.url;

    const response = await axios({
      ...params,
      url,
      headers: {
        Authorization: `Bearer ${getToken()}`,
        ...params.headers,
      },
      timeout: CONFIG.SECONDS_REQUEST_TIMEOUT * 1000,
    });

    return response;
  } catch (e) {
    if (e.response && e.response.status === 403) {
      dispatch(logout());
    }
    throw e;
  }
};

export default () => {
  dispatch = useDispatch();
  return request;
};
