import * as Yup from 'yup';
import { cpf as cpfValidation } from 'helpers/validations';
import { onlyNumbers } from 'helpers/masks';

function cpf(args = {}) {
  const defaultMessage = 'CPF inválido';
  const { message } = args;
  return this.test('cpf', message || defaultMessage, function validation(value = '') {
    const { createError } = this;
    const formattedValue = onlyNumbers(value);

    const isValid = cpfValidation(formattedValue);

    return value.length === 0
      || isValid
      || createError();
  });
}

function min(minLegth) {
  return this.test('min', '', function validation(value = '') {
    const { createError, schema, path } = this;
    const { _label: label } = schema;
    return value.length === 0
      || value.length >= minLegth
      || createError({
        path,
        message: `${label} deve conter no mínimo ${minLegth} caracteres`,
      });
  });
}

function max(maxLegth) {
  return this.test('max', '', function validation(value = '') {
    const { createError, schema, path } = this;
    const { _label: label } = schema;
    return value.length === 0
      || value.length <= maxLegth
      || createError({
        path,
        message: `${label} deve conter no máximo ${maxLegth} caracteres`,
      });
  });
}

Yup.addMethod(Yup.string, 'cpf', cpf);
Yup.addMethod(Yup.string, 'min', min);
Yup.addMethod(Yup.string, 'max', max);

export default Yup;
