import React from 'react';
import PropTypes from 'prop-types';

const Section = (props) => {
  const { children } = props;

  return (
    <div className="section">{children}</div>
  );
};

Section.defaultProps = {
};

Section.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Section;
