// Colors from: https://materializecss.com/color.html

export default {
  primary: {
    background: 'blue',
    backgroundLight: 'darken-4',
    text: 'white',
    textLight: 'lighten-0',
  },
  secondary: {
    background: 'black',
    backgroundLight: 'lighten-2',
    text: 'white',
    textLight: 'lighten-0',
  },
}
