import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Icon from 'Components/Common/Icon';

const Dropdown = (props) => {
  const { text, children } = props;

  const [id] = useState(Math.random());

  useEffect(() => {
    const dropdowns = document.querySelectorAll('.dropdown-trigger');
    window.M.Dropdown.init(dropdowns, { constrainWidth: false });
  }, []);

  return (
    <>
      <a href="#!" className="dropdown-trigger" data-target={id}>
        { text }
        <Icon right>arrow_drop_down</Icon>
      </a>
      <ul id={id} className="dropdown-content">
        { children }
      </ul>
    </>
  );
};

Dropdown.defaultProps = {

};

Dropdown.protoTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default Dropdown;
