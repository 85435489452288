import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import If from 'Components/Common/If';
import MaskedInput from 'react-input-mask';

const TextField = (props) => {
  const { name, type, label, mask } = props;
  const [inputVal, setInputVal] = React.useState("");

  const {
    errors, register, watch,
  } = useFormContext();

  const error = errors[name];

  return (
    <div className="input-field">
      {
        mask? 
        <MaskedInput
          mask={mask}
          onChange={(e) => setInputVal(e.target.value)}
          value={inputVal}
          label={label}
          type={type}
          id={name}
          name={name}
        >
          {(inputProps) => (
            <input
              ref={register}
              value={inputProps.inputVal}
              name={inputProps.name}
              {...inputProps}
            />
          )}
        </MaskedInput>
        :
        <input
          id={name}
          type={type}
          name={name}
          defaultValue={watch(name)}
          ref={register}
        />
      }
      
      <label htmlFor={name}>{label}</label>
      <If test={error}>
        <span className="helper-text red-text text-darken-2">{error?.message}</span>
      </If>
    </div>
  );
};

TextField.defaultProps = {
  type: 'text',
  mask: null
};

TextField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  mask: PropTypes.string,
};


export default TextField;
