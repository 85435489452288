import PACKAGE from '../package.json';
import logo from './assets/logo.png'

const development = {
  API_URL: 'https://api.portoprev.al.gov.br',
  VERSION: PACKAGE.version,
  SECONDS_REQUEST_TIMEOUT: 15,
  COMPANY: 'Previdência Social',
  LOCATION: 'Porto Calvo - AL',
  LOGO: logo,
  APP_NAME: 'Contra-Cheque OnLine'
};

const production = {
  ...development,
};

export default (process.env.NODE_ENV === 'production')
  ? production : development;
