export const cpf = (value = '') => {
  const onlyCpf = value.replace(/[^\d]+/g, '');
  if (!onlyCpf) {
    return false;
  }

  if (onlyCpf.length !== 11
    || onlyCpf === '00000000000'
    || onlyCpf === '11111111111'
    || onlyCpf === '22222222222'
    || onlyCpf === '33333333333'
    || onlyCpf === '44444444444'
    || onlyCpf === '55555555555'
    || onlyCpf === '66666666666'
    || onlyCpf === '77777777777'
    || onlyCpf === '88888888888'
    || onlyCpf === '99999999999') {
    return false;
  }

  let add = 0;

  for (let i = 0; i < 9; i += 1) {
    add += parseInt(onlyCpf.charAt(i), 10) * (10 - i);
  }

  let rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(onlyCpf.charAt(9), 10)) {
    return false;
  }
  add = 0;
  for (let i = 0; i < 10; i += 1) {
    add += parseInt(onlyCpf.charAt(i), 10) * (11 - i);
  }

  rev = 11 - (add % 11);

  if (rev === 10 || rev === 11) {
    rev = 0;
  }

  if (rev !== parseInt(onlyCpf.charAt(10), 10)) {
    return false;
  }

  return true;
}
