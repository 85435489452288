import axios from 'axios';
import CONFIG from 'config';
import { removeToken, setToken, verifyToken, getToken } from 'helpers/token';

export const login = (cpf, password) => {
  return async (dispatch) => {
    try {
      const { data } = await axios.post(
        `${CONFIG.API_URL}/login`,
        { cpf, password }
      );

      setToken(data.token);
      dispatch({
        type: 'LOGIN',
        payload: { ...data.user, token: data.token }
      });
    } catch (e) {
      removeToken();
      throw e;
    }
  }
};

export const loginWithToken = () => {
  return async (dispatch) => {
    const user = await verifyToken();
    if (user) {
      dispatch({
        type: 'LOGIN',
        payload: { ...user, token: getToken() }
      });
      return;
    }
    dispatch(logout());
  }
};

export const logout = () => {
  removeToken();
  return [
    { type: 'LOGOUT' },
    { type: 'RESET_PAGE_AFTER_LOGIN' },
  ];
};
