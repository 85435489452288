import React from 'react';
import { useSelector } from 'react-redux';
import { useForm, FormProvider } from 'react-hook-form';
import Yup from 'helpers/yup';
import { yupResolver } from '@hookform/resolvers';
import useRequest from 'helpers/useRequest';
import { toast } from 'react-toastify';
import Button from 'Components/Common/Button';
import TextField from 'Components/Common/Form/TextField';
import Row from 'Components/Common/Row';
import Col from 'Components/Common/Col';
import Section from 'Components/Common/Section';

const validationSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .label('Senha Atual')
    .required()
    .min(6)
    .max(18),
  password: Yup.string()
    .label('Senha')
    .required()
    .min(6)
    .max(18),
  passwordConfirmation: Yup.string()
    .label('Confirmar Senha')
    .required()
    .oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
});

export default () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit, formState, reset } = methods;
  const { isSubmitting } = formState;
  const { id } = useSelector((state) => state.user);
  const request = useRequest();

  const onSubmit = async (data) => {
    try {
      await request({
        url: `/users/${id}`,
        method: 'put',
        data,
      });

      reset();
      toast.success('Senha alterada com sucesso.');
    } catch (e) {
      toast.warn('Não foi possível alterar a senha.');
    }
  };

  return (
    <Row>
      <Col size={12}>
        <Section>
          <h5>Alterar Senha</h5>
          <FormProvider {...methods}>
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextField label="Senha Atual" name="currentPassword" type="password" />
              <TextField label="Nova Senha" name="password" type="password" />
              <TextField label="Confirmar Nova Senha" name="passwordConfirmation" type="password" />
              <Button submit disabled={isSubmitting}>Salvar</Button>
            </form>
          </FormProvider>
        </Section>
      </Col>
    </Row>
  );
};
