import React from 'react';
import PropTypes from 'prop-types';
import Link from 'Components/Common/Link';

const DropdownItem = (props) => {
  const { children, to } = props;
  return (
    <li>
      <Link to={to} transparent>
        { children }
      </Link>
    </li>
  );
};

DropdownItem.defaultProps = {
};

DropdownItem.protoTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  to: PropTypes.string.isRequired,
};

export default DropdownItem;
