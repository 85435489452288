import React, { useEffect } from 'react';
import { Route, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { changePageAfterLogin } from 'Redux/Actions/Page';
import { loginWithToken } from 'Redux/Actions/User';
import Header from 'Components/Common/Header';
import Sidenav from 'Components/Common/Sidenav';
import If from 'Components/Common/If';
import Page from 'Components/Common/Page';
import Container from 'Components/Common/Container';
import Title from 'Components/Common/Title';
import Row from 'Components/Common/Row';
import Col from 'Components/Common/Col';
import Section from 'Components/Common/Section';
import Divider from 'Components/Common/Divider';

export default (props) => {
  const { title, component: Component } = props;
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state);

  const verifyToken = () => {
    if (!user.token) {
      dispatch(changePageAfterLogin(history.location.pathname));
      history.push('/entrar');
      return;
    }

    if (user.token && !user.id) {
      dispatch(loginWithToken());
    }
  };

  useEffect(verifyToken, [user]);

  return (
    <>
      <Header />
      <Sidenav />
      <Route
        {...props}
        component={() => (
          <If test={user.id}>
            <Container>
              <Row>
                <Col size={12}>
                  <Section>
                    <Title>{title}</Title>
                  </Section>
                  <Divider />
                  <Section>
                    <Page title={title}>
                      <Component />
                    </Page>
                  </Section>
                </Col>
              </Row>
            </Container>
          </If>
        )}
      />
    </>
  );
};
