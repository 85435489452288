import { setLocale } from 'yup';

setLocale({
  mixed: {
    required: ({ label }) => `${label} é um campo obrigatório`,
    number: ({ label }) => `${label} é um campo obrigatório`,
  },
  string: {
    length: ({ label, length }) => `${label} deve conter ${length} caracteres`,
    email: ({ label }) => `${label} inválido`,
  },
});
