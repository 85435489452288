import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from 'Redux/Actions/User';

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logoutEffect = () => {
    dispatch(logout());
    history.push('/entrar');
  }

  useEffect(logoutEffect, []);

  return false;
}
