import React from 'react';
import PropTypes from 'prop-types';
import { Link as RouteLink } from 'react-router-dom';
import theme from 'Main/theme';

const Link = (props) => {
  const {
    children, to, disabled, transparent,
    sidenav, className, block, color, logo,
    target,
  } = props;

  const classes = [
    'waves-effect',
    'waves-light',
    'tiny'
  ];

  if (disabled) {
    classes.push('disabled');
  }

  if (!transparent) {
    classes.push('btn');
    classes.push(theme[color].background);
    classes.push(theme[color].backgroundLight);
    classes.push(`${theme[color].text}-text`);
    classes.push(`text-${theme[color].textLight}`);
  }

  if (transparent && !sidenav && !logo) {
    classes.push(`${theme[color].background}-text`);
    classes.push(`text-${theme[color].backgroundLight}`);
  }

  if (sidenav || logo) {
    classes.push('sidenav-close');
  }

  if (className) {
    classes.push(className.split(' '));
  }

  if (block) {
    classes.push('col');
    classes.push('s12');
  }

  if (block && transparent) {
    classes.push('center-align');
  }

  if (to.indexOf('http://') > -1 || to.indexOf('https://') > -1) {
    return (
      <a
        className={classes.join(' ')}
        href={to}
        target={target}
      >
        {children}
      </a>
    );
  }

  return (
    <RouteLink
      className={classes.join(' ')}
      to={to}
    >
      {children}
    </RouteLink>
  );
};

Link.defaultProps = {
  disabled: false,
  transparent: false,
  sidenav: false,
  logo: false,
  block: false,
  className: '',
  color: 'primary',
  target: null,
};

Link.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired,
  to: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  transparent: PropTypes.bool,
  sidenav: PropTypes.bool,
  logo: PropTypes.bool,
  block: PropTypes.bool,
  target: PropTypes.string,
  className: PropTypes.string,
  color: PropTypes.oneOf(['primary', 'secondary'])
};

export default Link;
