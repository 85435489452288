import React from 'react';
import PropTypes from 'prop-types';
import {
  Table as SuperTable, Thead, Tbody, Tr, Th, Td,
} from 'react-super-responsive-table';
import 'react-super-responsive-table/dist/SuperResponsiveTableStyle.css';
import './Style.css';

const Table = (props) => {
  const { columns, data } = props;

  return (
    <SuperTable>
      <Thead>
        <Tr>
          {columns.map((column) => (
            <Th key={column.field} style={column.style}>{column.label}</Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
          {data.map((line, index) => (
            <Tr key={index}>
              {columns.map((column) => {
                if (!column.render) {
                  return (
                    <Td style={column.style} key={column.field}>
                      {line[column.field]}
                    </Td>
                  );
                }
                return (
                  <Td style={column.style} key={column.field}>
                    {column.render(line)}
                  </Td>
                );
              })}
            </Tr>
          ))}
      </Tbody>
    </SuperTable>
  );
}

Table.defaultProps = {

};

Table.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Table;
