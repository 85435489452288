import React from 'react';
import { useSelector } from 'react-redux';
import theme from 'Main/theme';
import Container from 'Components/Common/Container';
import Icon from 'Components/Common/Icon';
import Dropdown from 'Components/Common/Dropdown';
import DropdownItem from 'Components/Common/DropdownItem';
import Link from 'Components/Common/Link';
import CONFIG from 'config'

export default () => {
  const classes = [
    'nav-wrapper',
    theme.primary.background,
    theme.primary.backgroundLight,
    `${theme.primary.text}-text`,
    `text-${theme.primary.textLight}`
  ];

  const user = useSelector((state) => state.user);

  return (
    <div className="navbar-fixed">
      <nav>
        <div className={classes.join(' ')}>
          <Container>
            <Link to="/" className="brand-logo" transparent logo>
            <div className="row hide-on-med-and-down" style={{lineHeight:'15px',marginTop:'7px',}}>
              <div className="col s2"><img alt="Logo" style={{width:'100%'}} src={CONFIG.LOGO}/></div>
              <div className="col s10">
                <span style={{fontSize:'16px'}}><b>{CONFIG.APP_NAME}</b></span><br/>
                <span style={{color:'lightgrey',fontSize:'14px'}}><small>{CONFIG.COMPANY} de {CONFIG.LOCATION}</small></span>
              </div>
            </div>
            

            </Link>
            <a href="#!" data-target="sidenav" className="sidenav-trigger">
              <Icon>menu</Icon>
            </a>
            <ul className="left hide-on-large-only">
              
              <div className="row " style={{lineHeight:'13px',marginTop:'16px',}}>
                <div className="col s2 m2 show-on-medium hide-on-small-only"><img alt="Logo" style={{width:'30px'}} src={CONFIG.LOGO}/></div>
                <div className="col s12 m10">
                  <span style={{fontSize:'15px'}}><b>{CONFIG.APP_NAME}</b></span><br/>
                  <span style={{color:'lightgrey',fontSize:'13px'}}><small>{CONFIG.COMPANY} de {CONFIG.LOCATION}</small></span>
                </div>
              </div>
            </ul>
            <ul id="nav-mobile" className="right hide-on-med-and-down">
              <li>
                <Link to="contracheques" transparent logo>
                  Contracheques
                  <Icon left>description</Icon>
                </Link>
              </li>
              <li>
                <Dropdown text={user.email}>
                  <DropdownItem to="configuracoes">
                    Configurações
                    <Icon left>settings</Icon>
                  </DropdownItem>
                  <DropdownItem to="sair">
                    Sair
                    <Icon left>exit_to_app</Icon>
                  </DropdownItem>
                </Dropdown>
              </li>
            </ul>
          </Container>
        </div>
      </nav>
    </div>
  );
};
