import { useEffect } from 'react';
import PropTypes from 'prop-types';

const Page = (props) => {
  const { title, children } = props;

  const changeTitle = () => {
    document.title = (title)
      ? `${title} - Previdência Social de Porto Calvo`
      : 'App';
  }

  useEffect(changeTitle, []);

  return children;
};

Page.defaultProps = {
  title: '',
};

Page.propTypes = {
  title: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
};

export default Page;
