import React from 'react';
import PropTypes from 'prop-types';

const Col = (props) => {
  const { children, size, offset } = props;

  const classes = [
    'col',
    `s${size}`,
    `offset-s${offset}`,
  ];

  return (
    <div className={classes.join(' ')}>{ children }</div>
  );
};

Col.defaultProps = {
  size: 1,
  offset: 0,
};

Col.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
  size: PropTypes.number,
  offset: PropTypes.number,
};

export default Col;
