import React from 'react';
import PropTypes from 'prop-types';
import theme from 'Main/theme';

const Button = (props) => {
  const {
    children, onClick, disabled,
    block, color, transparent, submit,
  } = props;

  const classes = [
    'waves-effect',
    'waves-light',
    'btn',
  ];

  if (!transparent) {
    classes.push('btn');
    classes.push(theme[color].background);
    classes.push(theme[color].backgroundLight);
    classes.push(`${theme[color].text}-text`);
    classes.push(`text-${theme[color].textLight}`);
  }

  if (transparent) {
    classes.push(`${theme[color].background}-text`);
    classes.push(`text-${theme[color].backgroundLight}`);
  }

  if (disabled) {
    classes.push('disabled');
  }

  if (block) {
    classes.push('col');
    classes.push('s12');
  }

  const type = submit ? 'submit' : 'button';

  return (
    <button
      type={type}
      onClick={onClick}
      className={classes.join(' ')}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  disabled: false,
  onClick: null,
  block: false,
  transparent: false,
  submit: false,
  color: 'primary',
};

Button.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]).isRequired,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  block: PropTypes.bool,
  submit: PropTypes.bool,
  transparent: PropTypes.bool,
  color: PropTypes.oneOf(['primary', 'secondary'])
};

export default Button;
