import React from 'react';
import { ToastContainer } from 'react-toastify';

export default () => (
  <ToastContainer
    containerId="toaster"
    draggable={false}
    closeButton={false}
    position="bottom-right"
  />
);
