
const INITIAL_STATE = {
  pageAfterLogin: '/contracheques',
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'CHANGE_PAGE_AFTER_LOGIN':
      return { ...state, pageAfterLogin: action.payload };
    case 'RESET_PAGE_AFTER_LOGIN':
      return { ...state, pageAfterLogin: INITIAL_STATE.pageAfterLogin };
    default:
      return state;
  }
}
