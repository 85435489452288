import React from 'react';
import CONFIG from 'config';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import Yup from 'helpers/yup';
import { yupResolver } from '@hookform/resolvers';
import { toast } from 'react-toastify';
import Link from 'Components/Common/Link';
import Button from 'Components/Common/Button';
import TextField from 'Components/Common/Form/TextField';
import Row from 'Components/Common/Row';
import Col from 'Components/Common/Col';
import Section from 'Components/Common/Section';

const validationSchema = Yup.object().shape({
  cpf: Yup.string()
    .label('CPF')
    .required()
    .cpf()
    .required("Favor informar um CPF.")
    .length(14, "O CPF de conter 11 dígitos."),
  email: Yup.string()
    .label('Email')
    .required("Favor informar um email.")
    .email('Email inválido.'),
  password: Yup.string()
    .label('Senha')
    .required("Favor informar uma senha.")
    .min(6, "A senha deve conter no mínimo 6 dígitos.")
    .max(18, "A senha deve conter no mínimo 18 dígitos."),
  passwordConfirmation: Yup.string()
    .label('Confirmar Senha')
    .required('Favor confirmar a senha.')
    .oneOf([Yup.ref('password'), null], 'Senhas diferentes'),
});

export default () => {
  const history = useHistory();
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit, formState } = methods;
  const { isSubmitting } = formState;

  const onSubmit = async (data) => {
    try {
      await axios.post(
        `${CONFIG.API_URL}/users`,
        data
      );

      toast.success('Cadastrado com sucesso.');
      history.push('entrar');
    } catch (e) {
      toast.warn('Não foi possível cadastrar.');
    }
  };

  return (
    <Row>
      <Col size={12}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Section>
              <TextField label="CPF" name="cpf" mask="999.999.999-99" />
              <TextField label="Email" name="email" type="email" />
              <TextField label="Senha" name="password" type="password" />
              <TextField label="Confirmar Senha" name="passwordConfirmation" type="password" />
              <Button block submit disabled={isSubmitting}>Cadastrar</Button>
            </Section>
          </form>
        </FormProvider>
      </Col>
      <Col size={12}>
        <Section>
          <Link to="entrar" transparent block>Acessar</Link>
        </Section>
      </Col>
    </Row>
  );
};
