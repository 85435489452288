import React, { useState, useEffect } from 'react';
import CONFIG from 'config';
import { getToken } from 'helpers/token';
import { useForm, FormProvider } from 'react-hook-form';
import useRequest from 'helpers/useRequest';
import { toast } from 'react-toastify';
import Table from 'Components/Common/Table';
import Row from 'Components/Common/Row';
import Col from 'Components/Common/Col';
import Section from 'Components/Common/Section';
import Link from 'Components/Common/Link';
import Icon from 'Components/Common/Icon';
import Select from 'Components/Common/Form/Select';

export default () => {
  const request = useRequest();
  const methods = useForm({
    defaultValues: { sort: 'competence_desc' }
  });
  const { watch } = methods;
  const [data, setData] = useState([]);
  const [lastSort, setLastSort] = useState(null);
  const currentSort = watch('sort');

  const columns = [
    { field: 'competence', style: { padding:0 }, label: 'Competência' },
    { field: 'description', style: { padding:0 }, label: 'Descrição' },
    { field: 'type', style: { padding:0 }, label: 'Tipo' },
    {
      field: 'id',
      label: 'Arquivo',
      style: { minWidth: 180, textAlign: 'center', padding:0 },
      render: (data) => (
        <Link to={`${CONFIG.API_URL}/paychecks/${data.id}/downloads?token=${getToken()}`} target="_blank">
          Baixar
          <Icon right>file_download</Icon>
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const getData = async (params) => {
      try {
        const { data: responseData } = await request({
          url: '/paychecks',
          method: 'get',
          params
        });

        setData(responseData);
      } catch (e) {
        toast.warn('Não foi possível consultar os contracheques')
      }
    };

    if (currentSort && currentSort !== lastSort) {
      setLastSort(currentSort);
      const [sort, order] = currentSort.split('_');
      const params = { sort, order };
      getData(params);
    }
  }, [request, currentSort, lastSort]);

  return (
    <Row>
      <Col size={12}>
        <Section>
          <FormProvider {...methods}>
            <form>
              <Select name="sort" label="Ordenar">
                <option value="competence_desc">Competência Decrescente</option>
                <option value="competence_asc">Competência Crescente</option>
              </Select>
            </form>
          </FormProvider>
        </Section>
        <div style={{height:'400px',overflow:'auto'}}>
          <Table data={data} columns={columns} />
        </div>
      </Col>
    </Row>
  );
};
