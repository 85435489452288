import React from 'react';
import PropTypes from 'prop-types';

const Container = (props) => {
  const { children } = props;
  return (
    <div className="container">
      { children }
    </div>
  );
};

Container.defaultProps = {
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default Container;
