import React from 'react';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import multi from 'redux-multi';
import { BrowserRouter } from 'react-router-dom';
import Routes from 'Components/Routes';
import reducers from 'Redux/Reducers';
import ToastContainer from 'Components/Common/ToastContainer';
import 'react-toastify/dist/ReactToastify.min.css'
import 'helpers/yup/language';

const store = createStore(reducers, applyMiddleware(thunk, multi));

export default () => {
  return (
    <div>
      <Provider store={store}>
        <BrowserRouter basename={`/${process.env.REACT_APP_BASENAME || ''}`}>
          <Routes />
        </BrowserRouter>
      </Provider>
      <ToastContainer />
    </div>
  );
};
