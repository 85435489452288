import React from 'react';
import PropTypes from 'prop-types';

const Icon = (props) => {
  const {
    children, size, push, left, right,
  } = props;

  const classes = [
    'material-icons',
    size,
    push,
  ];

  if (right) {
    classes.push('right');
  }

  if (left) {
    classes.push('left');
  }

  return (
    <i className={classes.join(' ')}>{children}</i>
  );
};

Icon.defaultProps = {
  size: 'small',
  left: false,
  right: false,
};

Icon.propTypes = {
  children: PropTypes.string.isRequired,
  size: PropTypes.oneOf(['tiny', 'small', 'medium', 'large']),
  left: PropTypes.bool,
  right: PropTypes.bool,
};

export default Icon;
