import React from 'react';
import useRequest from 'helpers/useRequest';
import { useForm, FormProvider } from 'react-hook-form';
import Yup from 'helpers/yup';
import { yupResolver } from '@hookform/resolvers';
import { toast } from 'react-toastify';
import Button from 'Components/Common/Button';
import Link from 'Components/Common/Link';
import TextField from 'Components/Common/Form/TextField';
import Row from 'Components/Common/Row';
import Col from 'Components/Common/Col';
import Section from 'Components/Common/Section';

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .label('Email')
    .required()
    .email(),
});

export default () => {
  const methods = useForm({
    resolver: yupResolver(validationSchema),
    validateCriteriaMode: 'firstErrorDetected',
    mode: 'onBlur',
  });

  const { handleSubmit, formState } = methods;
  const { isSubmitting } = formState;
  const request = useRequest();

  const onSubmit = async (data) => {
    try {
      await request({
        url: '/recovery-password',
        method: 'post',
        data,
      });
      toast.success('Email de recuperação enviado com sucesso.');
    } catch (e) {
      toast.warn('Não foi possível recuperar a senha.');
    }
  };

  return (
    <Row>
      <Col size={12}>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Section>
              <TextField label="Email" name="email" />
              <Button block submit disabled={isSubmitting}>Recuperar</Button>
            </Section>
          </form>
        </FormProvider>
      </Col>
      <Col size={12}>
        <Section>
          <Link to="entrar" transparent block>Entrar</Link>
        </Section>
      </Col>
    </Row>
  );
}
