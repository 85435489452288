import React from 'react';
import PropTypes from 'prop-types';

const Title = (props) => {
  const { children } = props;

  return (
    <h5 className="truncate">{children}</h5>
  );
}

Title.defaultProps = {
};

Title.propTypes = {
  children: PropTypes.string.isRequired,
};

export default Title;
