import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFormContext } from 'react-hook-form';
import If from 'Components/Common/If';

const Select = (props) => {
  const { name, label, children } = props;
  useEffect(() => {
    const selects = document.querySelectorAll('select');
    window.M.FormSelect.init(selects);
  }, []);

  const {
    errors, register, watch,
  } = useFormContext();

  const error = errors[name];

  return (
    <div className="input-field">
      <select id={name} name={name} defaultValue={watch(name)} ref={register}>
        {children}
      </select>
      <label>{label}</label>
      <If test={error}>
        <span className="helper-text red-text text-darken-2">{error?.message}</span>
      </If>
    </div>
  );
}

Select.defaultProps = {
  type: 'text',
};

Select.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.arrayOf(PropTypes.node),
  ]),
};

export default Select;
