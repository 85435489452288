import { getToken } from 'helpers/token';

const INITIAL_STATE = {
  id: null,
  email: '',
  cpf: '',
  token: getToken(),
};

export default function (state = INITIAL_STATE, action) {
  switch (action.type) {
    case 'LOGIN':
      return { ...state, ...action.payload };
    case 'LOGOUT':
      return { ...INITIAL_STATE, token: null };
    default:
      return state;
  }
}
